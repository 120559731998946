.view-toggle-btn {
    display: flex;
    border: 2px solid #1F3BB3;
    border-radius: 20px;
    overflow: hidden;
    background-color: #f8f9fa;
  }
  
  .toggle-option {
    flex: 1;
    padding: 5px 15px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 16px;
    color: #1F3BB3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .toggle-option.active {
    background-color: #1F3BB3;
    color: white;
  }
  
  .toggle-option i {
    margin-right: 5px;
  }
  