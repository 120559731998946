.auth .brand-logo {
  margin-bottom: 2rem;
  text-align: center !important;
}

.auth .brand-logo img {
  width: 180px !important;
}

.navbar .navbar-brand-wrapper .navbar-brand img {
  height: 50px !important;
  width: auto !important;
}

.navbar .navbar-brand-wrapper {
  background: #fff !important;
}

@media (max-width: 991px) {
  .navbar .navbar-brand-wrapper {
    background: none !important;
  }
}

.sidebar .nav .nav-item.active > .nav-link {
  background: #f4f5f7 !important;
}

.sidebar {
  background: #fff !important;
}

.border-radius {
  border-radius: 0.5rem;
  box-shadow: 0 3px 3px -2px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
    0 3px 4px 0 var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
    0 1px 8px 0 var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
  /* backdrop-filter: blur(6px); */
}

.content-wrapper {
  padding: 1.4rem 1rem 1rem 1rem !important;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
  top: 58px !important;
}

.modal .modal-dialog .modal-content .modal-header {
  padding: 15px 26px !important;
}

.modal .modal-dialog .modal-content .modal-body {
  /* padding: 0px 26px !important; */
  padding: 0px 26px 36px 26px !important;
}

.font-size {
  font-size: 23px;
  border: 0;
  background: transparent;
}

.setNav {
  height: 54px;
}

.mdi-magnify {
  height: 25px;
}

#signedIn {
  margin-left: -2px;
  margin-top: 2px;
}

.bowUGI {
  /* position: relative;
    display: flex;
    flex: 1 1 auto;
    box-sizing: border-box;
    -webkit-box-align: center;
    align-items: center;
    padding: 1px 0px 0px 1px;
    -webkit-box-pack: end;
    justify-content: flex-end;
    flex-wrap: wrap;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(139, 136, 136);
    border-radius: 7px;
    height: 38px;
    width: 25% !important;
    margin-bottom: 3%;
    margin-left: 70%; */
  justify-items: end;
}

.djsRDF {
  height: 32px;
  width: 200px;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid rgb(229, 229, 229);
  padding: 0px 32px 0px 16px;
}

.setIcon {
  height: 35px;
}

.set_button {
  float: right;
  margin-bottom: 10px;
  padding: 8px !important;
  display: flex !important;
  align-items: center;
}

.mailTable {
  overflow-x: auto;
}

.switchSize {
  font-size: medium;
}

.search_coupleData {
  display: flex;
  flex-direction: column;
}

.sunEditor {
  z-index: 1;
}

.multiSelect {
  z-index: 10;
}

.buttonMargin {
  margin-left: 1%;
}

.text-center {
  text-align: center !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.settings .media1 {
  border: 1px solid #d8d6de;
  padding: 1.2rem;
  border-radius: 0.425rem;
  margin-bottom: 1rem;
  width: 100%;
}

.settings .form-group {
  margin-bottom: 1.5rem;
}

.settings .card {
  padding: 10px;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card .card-description {
  color: #6a6b83;
  margin-bottom: 1.5rem;
  font-weight: 400;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.edit-profile-img img {
  height: auto;
  width: 220px;
  border-radius: 3% !important;
  border: 1px solid #b6b6b6;
}

.retreat_img img {
  border: unset !important;
  border-radius: unset !important;
  height: auto !important;
  width: 250px !important;
  /* object-fit: cover !important; */
}

@media (max-width: 768px) {
  .edit-profile-img {
    margin-top: 10px;
  }
}

.retreats .tooltip {
  min-width: 2px;
}

.Couple_heading {
  padding: 0 15px 0 15px;
}

.Heading_items {
  margin-top: 7px;
}

.upcoming-retreats .card.card-rounded {
  border: 2px solid rgba(52, 177, 170, 0.2);
}

.setcustom_border {
  border: 2px solid rgba(52, 177, 170, 0.2);
  border-radius: 15px;
}

.text-title {
  color: #9b8427 !important;
}

.send_mail {
  color: white !important;
}

/* .hbotQl {
    overflow: visible !important;
   width: 90px;
    text-overflow:unset !important;
} */

.space-bottom {
  margin-bottom: 10px;
}

.ext-space {
  margin-top: 22px !important;
}

.me-0 {
  margin-top: -8px;
}

.set_text {
  font-size: 10px !important;
  padding-right: 0px !important;
}

.set_text1 {
  font-size: 10px !important;
  padding-right: 0px !important;
  margin-top: -6px;
}

@media (max-width: 1075px) {
  .set_text1 {
    margin-top: -10px;
  }
}

@media (max-width: 768px) {
  .set_text1 {
    margin-top: -6px;
  }
}

@media (max-width: 281px) {
  .card_head {
    font-size: 15px !important;
  }
}

.btn-wrapper {
  margin-top: -9px;
}

.set_text {
  font-size: 10px !important;
  padding-right: 0px !important;
}

.btn-wrapper {
  margin-top: -9px;
}

.add-user {
  border: 1px solid #172d88;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  background: #172d88;
}

.text-brown {
  color: #9b8427 !important;
}

.setdate {
  height: 32px;
}

.profile {
  height: 60px;
  width: 100px;
  margin-left: 20px;
  border-radius: 3%;
  margin-right: 0.4rem;
  /* border: 1px solid #b6b6b6; */
  object-fit: contain;
}

.dropdown-item img {
  text-align: center;
}

.bold {
  font-weight: bold;
}

/* a {
    color: #484848 !important;
} */

.custom-card .card-body {
  padding: 1rem 0 !important;
}

.gKbhqU {
  min-height: 0 !important;
}

@media (max-width: 575px) {
  .btn-wrapper {
    margin-top: 0.5rem !important;
  }
}

[data-column-id="hisFirstName"].rdt_TableCol {
  background-color: #e0f8fe;
}

[data-column-id="hisLastName"].rdt_TableCol {
  background-color: #e0f8fe;
}

[data-column-id="hisEmail"].rdt_TableCol {
  background-color: #e0f8fe;
}

[data-column-id="hisMobile"].rdt_TableCol {
  background-color: #e0f8fe;
}

[data-column-id="herFirstName"].rdt_TableCol {
  background-color: #ffedfb;
}

[data-column-id="herLastName"].rdt_TableCol {
  background-color: #ffedfb;
}

[data-column-id="herEmail"].rdt_TableCol {
  background-color: #ffedfb;
}

[data-column-id="herMobile"].rdt_TableCol {
  background-color: #ffedfb;
}

[data-column-id="primaryKey"] {
  overflow: visible !important;
  width: 90px;
  text-overflow: unset !important;
}

[data-column-id="hisFirstName"] {
  overflow: visible !important;
  width: 90px;
  text-overflow: unset !important;
}

[data-column-id="hisLastName"] {
  overflow: visible !important;
  width: 90px;
  text-overflow: unset !important;
}

[data-column-id="hisEmail"] {
  overflow: visible !important;
  width: 150px;
  text-overflow: unset !important;
}

[data-column-id="hisMobile"] {
  overflow: visible !important;
  width: 90px;
  text-overflow: unset !important;
}

[data-column-id="herFirstName"] {
  overflow: visible !important;
  width: 90px;
  text-overflow: unset !important;
}

[data-column-id="herLastName"] {
  overflow: visible !important;
  width: 90px;
  text-overflow: unset !important;
}

[data-column-id="herEmail"] {
  overflow: visible !important;
  width: 150px;
  text-overflow: unset !important;
}

[data-column-id="herMobile"] {
  overflow: visible !important;
  width: 90px;
  text-overflow: unset !important;
}

[data-column-id="city"] {
  overflow: visible !important;
  width: 90px;
  text-overflow: unset !important;
}

[data-column-id="state"] {
  overflow: visible !important;
  width: 90px;
  text-overflow: unset !important;
}

[data-column-id="notes"] {
  overflow: visible !important;
  width: 90px;
  text-overflow: unset !important;
}

[data-column-id="created"] {
  overflow: visible !important;
  width: 90px;
  text-overflow: unset !important;
}

[data-column-id="status"] {
  overflow: visible !important;
  width: 380px;
  text-overflow: unset !important;
}

[data-column-id="makeAdmin"] {
  overflow: visible !important;
  width: 100px;
  text-overflow: unset !important;
}

[data-column-id="action"] {
  overflow: visible !important;
  width: 100px;
  text-overflow: unset !important;
}

.Delete_img {
  text-align: center;
}

.icon-box {
  height: 125px;
  padding: 10px;
}

.outerDot {
  margin-top: 3px;
}

.dotted {
  width: 7px;
  height: 7px;
  background-color: #b2b2b2;
  border-radius: 50%;
  display: inline-block;
}

.dot1 {
  height: 13px;
  width: 13px;
  background-color: #cc0000;
  border-radius: 50%;
  display: inline-block;
}

.dot2 {
  height: 13px;
  width: 13px;
  background-color: #cccc00;
  border-radius: 50%;
  display: inline-block;
}

.dot3 {
  height: 13px;
  width: 13px;
  background-color: #000099;
  border-radius: 50%;
  display: inline-block;
}

.dot4 {
  height: 13px;
  width: 13px;
  background-color: #009900;
  border-radius: 50%;
  display: inline-block;
}

.dot5 {
  height: 13px;
  width: 13px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}

.dot5 :hover {
  height: 13px;
  width: 13px;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
}

.line1 {
  background-color: #cc0000;
  height: 39px;
  width: 6px;
  display: inline-block;
  position: absolute;
  left: 0;
}

.line2 {
  height: 39px;
  width: 6px;
  background-color: #cccc00;
  display: inline-block;
  position: absolute;
  left: 0;
}

.line3 {
  height: 39px;
  width: 6px;
  background-color: #000099;
  display: inline-block;
  position: absolute;
  left: 0;
}

.line4 {
  height: 39px;
  width: 6px;
  background-color: #009900;
  display: inline-block;
  position: absolute;
  left: 0;
}

.line5 {
  height: 39px;
  width: 6px;
  background-color: transparent;
  display: inline-block;
  position: absolute;
  left: 0;
}

.head_items {
  justify-content: space-between;
}

.circles .dot1 {
  margin-left: 20px;
}

.circles .dot2 {
  margin-left: 20px;
}

.circles .dot3 {
  margin-left: 20px;
}

.circles .dot4 {
  margin-left: 20px;
}

.circles h6 {
  margin-left: 5px;
  font-size: 13px !important;
}

.circles {
  justify-content: end;
  display: flex;
}

@media (max-width: 534px) {
  .circles {
    margin-bottom: -25px;
    justify-content: unset !important;
  }

  .Set_clear {
    margin-left: 25px;
  }
}

.circles2 {
  display: flex;
}

@media (max-width: 417px) {
  .circles2 {
    display: unset;
  }
}

.dot1 {
  height: 13px;
  width: 13px;
  background-color: #cc0000;
  border-radius: 50%;
  display: inline-block;
}

.dot2 {
  height: 13px;
  width: 13px;
  background-color: #cccc00;
  border-radius: 50%;
  display: inline-block;
}

.dot3 {
  height: 13px;
  width: 13px;
  background-color: #000099;
  border-radius: 50%;
  display: inline-block;
}

.dot4 {
  height: 13px;
  width: 13px;
  background-color: #009900;
  border-radius: 50%;
  display: inline-block;
}

.dot5 {
  height: 13px;
  width: 13px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}

.dot5 :hover {
  height: 13px;
  width: 13px;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
}

.line1 {
  background-color: #cc0000;
  height: 39px;
  width: 6px;
  display: inline-block;
  position: absolute;
  left: 0;
}

.line2 {
  height: 39px;
  width: 6px;
  background-color: #cccc00;
  display: inline-block;
  position: absolute;
  left: 0;
}

.line3 {
  height: 39px;
  width: 6px;
  background-color: #000099;
  display: inline-block;
  position: absolute;
  left: 0;
}

.line4 {
  height: 39px;
  width: 6px;
  background-color: #009900;
  display: inline-block;
  position: absolute;
  left: 0;
}

.line5 {
  height: 39px;
  width: 6px;
  background-color: transparent;
  display: inline-block;
  position: absolute;
  left: 0;
}

.head_items {
  justify-content: space-between;
}

.circles .dot1 {
  margin-left: 20px;
}

.circles .dot2 {
  margin-left: 20px;
}

.circles .dot3 {
  margin-left: 20px;
}

.circles .dot4 {
  margin-left: 20px;
}

.circles h6 {
  margin-left: 5px;
  font-size: 13px !important;
}

.circles {
  justify-content: end;
  display: flex;
}

@media (max-width: 534px) {
  .circles {
    margin-bottom: -25px;
    justify-content: unset !important;
  }

  .Set_clear {
    margin-left: 25px;
  }
}

.circles2 {
  display: flex;
}

@media (max-width: 417px) {
  .circles2 {
    display: unset;
  }
}

.rdt_TableHeadRow {
  min-height: 40px !important;
}

.rdt_TableHeadRow {
  min-height: 40px !important;
}

.totalData {
  margin-top: 16px;
  margin-bottom: -19px;
}

.setspan {
  font-size: 15px;
  color: #cca91d;
}

.sun-editor-editable {
  height: 170px !important;
}

.impbtn {
  height: 34px;
  display: flex !important;
}

.dropset {
  margin-top: -8px;
  margin-right: 5px;
}

.default {
  cursor: pointer;
}

.default1 {
  cursor: pointer;
}

.default1 {
  color: black;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.set_default {
  cursor: pointer;
  color: black;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  float: left;
}

.count_data {
  background-color: #e1e1e1;
  margin: 2px 0px;
  padding: 5px 0px 0px;
  font-size: 16px !important;
}

.dropdata {
  margin-bottom: -50px;
  margin-top: -20px;
  float: right;
  width: 150px;
}

.btn-rect-md {
  width: 100% !important;
  height: 100px !important;
  text-align: center;
  padding: 0px;
  font-size: 1.2rem !important;
  line-height: 1.4rem !important;
}

@media (max-width: 1140px) {
  .btn-rect-md {
    font-size: 1rem !important;
  }
}

@media (max-width: 1062px) {
  .btn-rect-md {
    font-size: 0.9rem !important;
  }
}

@media (max-width: 768px) {
  .btn-rect-md {
    font-size: 1.2rem !important;
  }
}

.btn-rect-md-font {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.btn-warning:hover,
.btn-warning:focus {
  background: #b17c07 !important;
  color: #ffffff;
  border-color: #b17c07 !important;
}

.btn-warning {
  background: #cca91d !important;
  border-color: #cca91d !important;
}

.dashbtn:hover,
.btn-info:focus {
  background: #269eda !important;
  color: #ffffff;
  border-color: #269eda !important;
}

.dashbtn {
  background: #62b9e4 !important;
  color: #ffffff;
  border-color: #62b9e4 !important;
}

.typedata {
  width: 100%;
}

.setclear {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 533px) {
  .setclear {
    display: unset;
  }
}

select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

select.minimal:focus {
  background-image: linear-gradient(45deg, green 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, green 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

/* .space_bet{
justify-content: space-between;
} */

.form-check .form_label {
  margin-left: 1px !important;
  margin-top: 2px;
}

.space_lef {
  padding-left: 60px !important;
}

.space_lef1 {
  padding-left: 50px !important;
}

.space_lef1 .form-check-label {
  margin-left: unset !important;
  margin-top: 1px;
}

.mailbtn {
  float: right !important;
  color: white !important;
  display: flex !important;
  align-items: center;
  /* margin-top: -40px; */
  padding: 0.5rem 0.8rem !important;
  height: 36px;
}

.alignbtn {
  display: flex !important;
  align-items: center;
  margin-top: -1px;
}

.extra_space {
  margin-bottom: 35px;
}

.cusror_point {
  cursor: pointer;
  text-decoration: underline;
  color: #000099;
  margin-right: 65%;
}

@media (max-width: 540px) {
  .cusror_point {
    margin-right: 20%;
  }
}

.column-rowImage {
  border-radius: 3%;
  height: 32px;
  max-width: 44px;
}

.column-rowImage-profile {
  max-width: 44px;
  height: 32px;
  border-radius: 3%;
  border: 1px solid #b6b6b6;
}

.column-rowImagePopover {
  width: 220px;
  height: 120px;
  border-radius: 3%;
  margin-bottom: 0.5rem;
  object-fit: contain;
}

.popover {
  position: absolute;
  inset: 0px auto auto 0px;
  transform: translate(325.556px, 206.667px);
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
  max-width: 250px !important;
  min-width: 250px !important;
  border: 2px solid #9b8427 !important;
}

.couplename {
  color: #9b8427 !important;
  width: 220px;
  text-align: center;
  display: contents;
}

.popover-body {
  padding: 0.2rem 0 0 0 !important;
}

.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #9b8427 !important;
}

.content1 {
  float: right;
}

.card-body .set_padding {
  padding-bottom: 0.2rem !important;
}

.datepicker::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  min-height: 18px;
  display: block;
  margin-left: 1.75rem;
  font-size: 0.812rem;
  line-height: 1.5;
}

.import_buttons {
  display: flex;
}

.import_drop1 {
  float: right;
}

.loading-overlay {
  height: 40px;
}

.exportData {
  margin-right: 10px;
}

@media (min-width: 338px) {
  .import_drop {
    display: flex;
    justify-content: space-around;
  }

  .import_drop1 {
    display: flex;
    justify-content: space-around;
  }
}

@media (max-width: 338px) {
  .import_buttons {
    display: unset;
  }

  .set_text {
    font-size: 14px !important;
  }

  .set_text1 {
    font-size: 14px !important;
    margin-top: -10px !important;
  }

  .import_button {
    width: 88px;
  }

  .import_drop {
    display: flex;
    justify-content: space-around;
  }

  .import_drop1 {
    display: flex;
    justify-content: space-around;
  }
}

.filteredData {
  justify-content: space-between;
}

.collap {
  border: none;
  padding: 8px 15px !important;
  float: right;
  font-size: 20px;
  width: auto !important;
  margin-bottom: 15px;
  margin-right: 13px;
}

.cllapse_out {
  margin-top: -21.8px;
}

.margin_set {
  margin-top: -43px !important;
  margin-bottom: 12px !important;
}

.mdi-plus-circle {
  margin-right: 1px;
  margin-top: 3px;
}

.mdi-account-multiple {
  margin-right: 3px;
}

.tab-content {
  padding-top: unset !important;
  font-weight: 200 !important;
}

.searchSet {
  margin-top: -5px;
}

.modal-lg .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  font-weight: bold;
}

.modal-lg .nav {
  padding: 0 1rem !important;
}

.modal-lg .modal-header {
  border-bottom: 0 !important;
}

/* .searchable {
	border: 1px solid black;
}

.searchable ::placeholder {
	color: black;
	font-size: large;
} */

.retreat_title {
  color: #000;
  text-decoration: unset;
  font-size: 15px;
  font-weight: bold;
}

.retreat_title :hover {
  color: #000;
  text-decoration: underline;
}

.attendRetreat {
  color: red;
}

.attendTeam {
  color: green;
}

.input-group,
.asColorPicker-wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group .searchData {
  height: 36px !important;
  border: 1px solid #727377;
}

.input-group-append,
.input-group-prepend {
  color: #c9c8c8;
  width: auto;
  border: none;
}

.setSearch ::placeholder {
  color: black !important;
  opacity: 1;
}

.setSearch :-ms-input-placeholder {
  color: black !important;
}

.setSearch::-ms-input-placeholder {
  color: black !important;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.input-group-append button {
  border-radius: 1px !important;
}

.search {
  margin-right: 17px;
}

.tab-content {
  border-top: 0;
  padding: 2rem 1rem;
  text-align: left;
  border: unset !important;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg) /* rtl:ignore */;
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg) /* rtl:ignore */;
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.spinner-position {
  margin-top: -27px;
  float: right;
  margin-right: 10px;
}

.modal_table {
  margin-top: 15px;
  overflow-x: hidden !important;
}

.titleWithDate {
  padding: 2px 0px 2px 0px;
}

.coupleCount {
  color: red;
}

.rdt_TableBody {
  height: 380px;
  /* margin-right: -15px; */
  overflow-y: auto;
}

.coupleDesc {
  font-weight: 500;
}

.select.form-control {
  color: unset !important;
}

.colorTalks {
  box-shadow: 0 3px 3px -2px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
    0 3px 8px 0 var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
    0 1px 6px 0 var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
  border-radius: 8px;
  padding-top: 10px;
}

.newroaster {
  margin-top: 6px;
  margin-left: 5px;
  height: 40px;
}

.Set_clear {
  cursor: pointer;
}

.selectData {
  width: 110px !important;
}

.registerNav {
  background: #fff !important;
}

.regisPadding {
  padding-top: unset !important;
}

.retreat-title-regis {
  padding: 10px;
}

.retreat-Title-Regis h3 {
  color: #cc3333;
  font-weight: 700;
  font-size: 1.6rem;
  /* border-bottom: 1px solid red; */
}

.retreat-Title-Regis p {
  color: #cc3333;
  font-weight: 700;
  font-size: 1.6rem;
}

.ageLabel {
  margin-right: 8px;
}

.registerLogo {
  height: 125px;
  padding: 20px;
}

.registerHeader {
  display: flex;
  justify-content: space-between;
}

.logInRegister {
  display: flex;
}

.vertical-line {
  border-left: 3px solid black; /* Adjust properties as needed */
  height: 30px !important; /* Adjust height as needed */
  margin-top: 42px;
  margin-right: 10px;
}

.logInRegister h4 {
  margin-right: 15px;
  margin-top: 49px;
}

.registerTag {
  color: #a28c36;
}

.retreat-Title-Regis {
  justify-content: center;
}

.horizontal-line {
  margin-top: -20px !important;
  justify-content: center;
  width: 569px !important;
  margin-left: 233px;
}

.retreat-Title-Regis p {
  margin-top: 20px;
}

.hear-options {
  justify-content: space-between;
}

.main-register {
  border-radius: 15px;
  margin-top: 40px;
}

.view-register {
  border-radius: 15px;
  margin-top: 20px;
}

.logInRegister a {
  text-decoration: unset;
}

.registration-retreat {
  color: #a28c36;
}

.couple-Login {
  color: #000;
}

.timePicker {
  width: 80px !important;
  height: 38px !important;
  border-radius: 5px !important;
}

.tp_inputWrapper {
  border: 1px solid #a49f9f !important;
}

.timePicker .react-time-picker__wrapper {
  border: 1px solid #dee2e6 !important;
}

.themesOfRetreat {
  margin-left: 6px;
}

.addDate {
  height: 37px;
  width: 36px;
  margin-left: 18px;
  margin-top: 97px;
}

.retreatDesc {
  height: 200px !important;
  line-height: 18px !important;
}

.fieldTheme {
  justify-content: space-around;
}

.deleteButton {
  font-size: 22px;
  margin-top: 4px;
  cursor: pointer;
}

/* .churchImage img {
  border: unset !important;
  height: 230px !important;
  object-fit: cover !important;
  width: 240px !important;
} */

.church_img img {
  height: 200px;
  width: auto;
  max-width: 450px;
}

.churchImage {
  /* display: flex; */
  justify-content: center;
  /* display: flex;
  align-items: flex-start; */
}

.church_img {
  display: flex;
  justify-content: center;
}

.headingDec {
  background-color: #ebc028;
  background-image: linear-gradient(#ebc028, #e2cf8a);
}

.headingDec h2 {
  margin: 15px;
  font-size: 27px;
  font-weight: 700;
  line-height: normal;
}

.tableBorder {
  border: 1px solid black;
}

.tableBorder tr th {
  border: 1px solid !important;
  border-bottom-color: 1px solid !important;
}

.tableBorder td {
  border: 1px solid;
}

.tableBorder tr {
  border: 1px solid;
}

.retreatOptions {
  font-size: 17px !important;
  font-weight: 500 !important;
  margin: 5px;
}

.retreatSelect {
  background-color: rgba(211, 190, 115, 255) !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  margin: 5px;

  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.RegisTitle {
  font-weight: 800;
}

.retreatParish {
  font-weight: 800;
  color: rgba(213, 186, 88, 255);
}

.parishAddress {
  color: rgba(213, 186, 88, 255);
}

.rolesDesign {
  font-weight: 500;
  font-size: 13px;
}

.missionState h4 {
  font-weight: 700;
}

.missionState p {
  font-weight: 500;
  font-size: 15px;
  font-family: "Roboto", sans-serif !important;
}

.descOfRetreat {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #353535;
  font-family: "Roboto", sans-serif !important;
}

.cardStyle {
  border: 1px solid rgba(255, 173, 132, 255) !important;
}

.cardStyle img {
  padding: 27px;
  max-height: 245px;
}

.loginLinkInThanku {
  margin-right: 100px;
}

.underLineText {
  border-bottom: 3px solid red;
}

.mailDEsign {
  text-decoration: none !important;
}

.formStyle ::placeholder {
  color: rgb(98, 94, 94) !important;
  opacity: 1;
}

.formStyle ::-ms-input-placeholder {
  color: rgb(86, 83, 83) !important;
}

.locationDropdown {
  color: rgb(86, 83, 83) !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

@media (max-width: 766px) {
  .mentorCouples {
    margin-top: 20px !important;
  }
}

@media (max-width: 575px) {
  .leadCoCouples {
    margin-top: 20px !important;
  }
}

@media (max-width: 657px) {
  .retreat-Title-Regis h3 {
    font-size: 17px;
  }

  .retreat-Title-Regis p {
    font-size: 17px;
    margin-top: -2px !important;
  }
}

@media (max-width: 458px) {
  .retreat-Title-Regis h3 {
    font-size: 13px;
  }

  .retreat-Title-Regis p {
    font-size: 13px;
    margin-top: -4px !important;
  }
}

@media (max-width: 500px) {
  .logInRegister h4 {
    margin-right: 5px;
    margin-top: 49px;
    font-size: 12px;
  }

  .vertical-line {
    margin-right: 5px;
  }

  .registerLogo {
    height: 108px;
  }
}

.coupleImage {
  height: 285px !important;
  max-height: 285px !important;
  object-fit: contain;
  padding: 20px !important;
}

.leadCoCouples img {
  height: 285px !important;
  max-height: 285px !important;
  object-fit: contain;
  padding: 20px !important;
}

.retreatCoupleImg img {
  height: 170px !important;
  padding: 10px !important;
  border-radius: 20px;
}

.mentorCouples img {
  height: 285px !important;
  max-height: 285px !important;
  object-fit: contain;
  padding: 20px !important;
}

.leadBody {
  padding: 0px !important;
}

.checkImageEdit {
  border: 1px solid red;
}

#his-info label {
  color: #06c6f6;
}

#her-info label {
  color: #ff6f7b;
}

#his-info input {
  font-size: 0.8125rem;
}

#her-info input {
  font-size: 0.8125rem;
}

/* .his-info-border {
  border: 1px solid #06c6f6 !important;
} */

/* .her-info-border {
  border: 1px solid #ec878f !important;
} */

.hisLabel {
  border: 1px solid #06c6f6 !important;
  color: #06c6f6 !important;
}

.herLabel {
  border: 1px solid #ff6f7b !important;
  color: #ff6f7b !important;
}

.locationFields input {
  font-size: 0.8125rem !important;
}

.check {
  margin-top: 4px;
}

/* .loginHeight {
  min-height: 80vh !important;
} */

.LeaderShip {
  padding: 70px;
  padding: 10px 92px 0px 92px;
}

.directoryHeader {
  background-color: #1f4e7a;
  color: white;
  text-align: center;
  margin-top: 32px !important;
}

.directoryHeader div {
  padding: 50px;
  word-wrap: break-word;
}

.directoryHeader div h1 {
  font-weight: bold;
}

.directoryHeaderInMain {
  background-color: #1f4e7a;
  color: white;
  text-align: center;
}

.headingInMain {
  margin-top: -9px !important;
}

.directoryHeaderInMain div {
  padding: 50px;
}

.directoryHeaderInMain div h1 {
  font-weight: bold;
}

.directoryImg img {
  /* margin-inline-start: -15px; */
  /* width: 1151px; */
  height: 400px;
  object-fit: cover;
  /* object-position: top; */
}

.directoryImgMain img {
  /* margin-inline-start: -15px; */
  /* width: 1064px; */
  height: 400px;
  object-fit: cover;
  /* object-position: top; */
}

.directoryImg7 img {
  margin-inline-start: -48px;
  width: 1151px;
  height: 400px;
  object-fit: cover;
  /* object-position: top; */
}

.dir-main img {
  /* margin-inline-start: -48px; */
  /* width: 1064px; */
  height: 400px;
  object-fit: cover;
  /* object-position: top; */
}

.directoryImg1 img {
  margin-inline-start: 136px;
  width: 800px;
  height: 400px;
  object-fit: cover;
}

.directoryImg1 img {
  margin-inline-start: 136px;
  width: 800px;
  height: 400px;
  object-fit: cover;
}

.directoryHeader div h2 {
  font-size: 34px;
  font-weight: bold;

  margin-top: 25px;
  margin-bottom: 15px;
}

.LeaderShip h2 {
  font-size: 40px;
  font-weight: bold;
}

.coupleImages img {
  height: 200px;
  width: 280px;
}

.coupleImages5 img {
  height: 100px;
  width: auto;
}

.LeadHeading {
  font-size: 38px;
  font-weight: 700;
}
.coupl_detail {
  text-align: left !important;
  padding-left: 45px !important;
}

.coupl_detail h2 {
  font-weight: bold;
}
.coupl_detail h3 {
  font-weight: 600;
}
.coupl_detail h4 {
  font-weight: bold;
}

.directory_footer {
  padding: 70px 160px !important;
}

.retdet {
  background-color: #1f4e7a;
  margin-top: 32px;
  padding: 10px;
  color: #fff;
}

.retdet1 {
  background-color: #1f4e7a;
  margin-top: -9px;
  padding: 10px;
  color: #fff;
}

.dirTitle {
  font-size: 40px;
  font-weight: 800;
  color: #1f4e7a !important;
  padding: 15px;
}

.buttonContainer {
  z-index: 15;
  margin-top: -20%;
  float: right;
  margin-right: -95px;
  display: none;
}

.howerOption:hover .buttonContainer {
  display: block;
}

.showEditButton {
  z-index: 15;
  float: right;
  display: none;
}
.coupleImageHower:hover .showEditButton {
  display: block;
}

.button-18 {
  align-items: center;
  background-color: #0a66c2;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto,
    "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell,
    "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Lucida Grande", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 30px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}

.button-18:hover,
.button-18:focus {
  background-color: #16437e;
  color: #ffffff;
}

.button-18:active {
  background: #09223b;
  color: rgb(255, 255, 255, 0.7);
}

.button-18:disabled {
  cursor: not-allowed;
  background: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.3);
}

.setUploadField {
  margin-top: -32px !important;
  margin-left: 262px;
}

.removeButton {
  font-size: 10px !important;
  padding: 5px !important;
}

.mainHeaderImage {
  position: relative;
}

.imageHeader {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle_Icon {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 99%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.middle_Icon3 {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 97.5%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.middle_Icon1 {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  /* top: 0%; */
  left: 90%;
  bottom: -28%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  font-size: 30px;
}

.middle_Icon2 {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 88.5%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.middle_Icon-Header-img {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 0%;
  left: 97.5%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.mainHeaderImage:hover .imageHeader {
  opacity: 0.3;
}

.mainHeaderImage:hover .middle_Icon {
  opacity: 1;
}

.mainHeaderImage:hover .middle_Icon3 {
  opacity: 1;
}
.mainHeaderImage:hover .middle_Icon1 {
  opacity: 1;
}

.mainHeaderImage:hover .middle_Icon2 {
  opacity: 1;
}

.mainHeaderImage:hover .middle_Icon-Header-img {
  opacity: 1;
}

.button-42 {
  background-color: initial;
  background-image: linear-gradient(-180deg, #ff7e31, #e62c03);
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #ffffff;
  cursor: pointer;
  font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue", Arial,
    sans-serif;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 20px;
  pointer-events: auto;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  white-space: nowrap;
  z-index: 9;
  border: 0;
  transition: box-shadow 0.2s;
}

.button-42:hover {
  box-shadow: rgba(253, 76, 0, 0.5) 0 3px 8px;
}

.buttonContainer1 {
  /* position: relative; */
  /* z-index: 15; */
  margin-top: -2%;
  float: right;
  margin-right: -32px;
  display: none;
}

.howerOption:hover .buttonContainer1 {
  display: block;
}

.footerTitle {
  border: 1px solid rgb(158, 155, 155);
  padding: 20px;
}

.footerTitle span {
  line-height: 28px;
}

.shadowImage {
  text-align: right !important;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.imageHeader {
  text-align: right !important;
}

.footer_images img {
  height: 300px;
  object-fit: cover;
  object-position: top;
}

.logo_image {
  height: 200px;
  margin-top: 13px;
  margin-bottom: 9px;
}

.directoryImage {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.table_Content_Data h2 {
  font-weight: 800;
  color: #1f4e7a;
}

.table_content {
  font-weight: 600;
  /* padding: 0px 130px 0px 130px; */
}

.table_content h3 {
  font-weight: 600;
  font-size: 22px;
  color: #1f4e7a;
  /* float: left; */
}

.logo_image1 {
  height: 110px;
  margin-bottom: 9px;
}

.speakersQuestions {
  background: #1f4e7a;
  color: white;
  padding: 10px;
}

.wisdomData {
  font-weight: 800;
  margin-top: 30px;
  margin-bottom: 30px;
}

.speakersQuestions h3 {
  font-size: 35px;
  font-weight: 800;
}
.speakersQuestions h4 {
  font-size: 27px;
  font-weight: 600;
}

.parishDetail {
  text-align: left;
}
.parishDetail h3 {
  font-weight: 700;
  margin-top: 15px;
}

.parishDetail h5 {
  font-weight: 600;
}

.qrCode {
  height: 185px;
}

.qrUpload {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  /* top: 0%; */
  left: 64%;
  bottom: 30%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  font-size: 30px;
}

.qrUpload0 {
  position: relative;
  margin-left: 107px !important;
  /* margin-top: -53px; */
  top: -33px;
}

.mainHeaderImage:hover .qrUpload {
  opacity: 1;
}

.parishLogo {
  max-height: 160px;
  max-width: 190px;
}

.parishLogo1 {
  max-height: 130px;
  max-width: 180px;
}
.logo_image5 {
  height: 170px;
  margin-bottom: 9px;
}

.set-Upload {
  margin-left: 35px;
  margin-top: -3px;
  margin-bottom: 11px;
}

.talk-question {
  padding: 10px;
}

.serviceTerms {
  font-size: 28px;
  font-weight: 600;
}

.dirPage {
  text-align: right;
  margin-right: 208px;
  margin-top: 10px !important;
}

.pageButton {
  margin-left: 31px;
}

.dirPage a {
  color: black;
  cursor: pointer;
  font-weight: 600;
}

.popup-buttons {
  justify-content: space-between !important;
}

.faceBookField {
  font-size: 16px;
}

.left-retreat-panel,
.right-panel,
.right-retreat-panel {
  height: 100vh;
  overflow-y: scroll;
  position: -webkit-sticky;
}

.left-retreat-panel::-webkit-scrollbar,
.right-retreat-panel::-webkit-scrollbar {
  width: 12px;
}

.left-retreat-panel::-webkit-scrollbar-track,
.right-retreat-panel::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.left-retreat-panel::-webkit-scrollbar-thumb,
.right-retreat-panel::-webkit-scrollbar-thumb {
  background: #888;
}

.left-retreat-panel::-webkit-scrollbar-thumb:hover,
.right-retreat-panel::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.left-retreat-panel .scrollable-content {
  direction: ltr; /* Normal text direction */
}

.scrollable-content {
  /* padding: 20px; */
  height: 120%;
  box-sizing: border-box;
}

.submitRet {
  float: right;
  margin-top: -2px;
  margin-left: 7px;
}

.addRetHeading {
  font-size: 28px;
  font-weight: 800;
}

.addNewRet {
  text-decoration: none;
}

.back-link {
  margin-left: 15px;
  text-decoration: none;
  font-size: 17px;
  font-weight: 600;
}

.image-set {
  padding: 0px 109px !important;
}

.sideProgram {
  padding: 20px;
}

/* .sideProgram:hover {
  background-color: #099;
  color: white;
  cursor: pointer;
} */

.sideProgram h5 {
  font-weight: 700;
  font-size: 17px;
}

.talk-question {
  font-weight: 700;
  font-size: 22px;
  text-align: left;
}

.item-talk {
  cursor: pointer;
}

.add-role-in-program h5 {
  font-weight: 700;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: left;
}

.add-role-in-program p {
  font-weight: 500;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.add-service-buttons {
  width: 12rem;
}

.add-role-couple {
  width: 12rem;
}

.selctSubmit {
  margin-left: 26px;
  margin-top: 61px;
}

.selct-role-Submit {
  margin-left: 26px;
  margin-top: 61px;
}

.selct-access-Submit {
  margin-left: 26px;
  margin-top: 15px;
}

.program-upper-title {
  margin-left: 5px;
}

.program-upper-title h6 {
  font-size: 15px;
  font-weight: 700;
}

.uploadMdi {
  /* margin-left: 110px; */
  font-size: 27px;
  margin-top: -9px;
}

.uploadMdi-delete {
  font-size: 33px;
  margin-top: -5px;
}

.uploadMdi1 {
  /* margin-left: 155px; */
  font-size: 33px;
  margin-top: -5px;
}

.plus-icon-role {
  font-size: 30px;
}

.sideListData {
  float: left;
}

.set-text-heading {
  text-align: left;
}

.master-couple {
  text-align: left;
}

.delete-icon i {
  font-size: 28px !important;
}

.st_slash {
  margin-left: 20px;
}

@media (max-width: 767px) {
  .st_slash {
    margin-left: -60px;
  }
}

@media (max-width: 1251px) {
  .table_content h3 {
    font-size: 17px;
  }
}

@media (max-width: 767px) {
  .table_content h3 {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .table_content h3 {
    font-size: 12px;
  }
}

.image-set-retreat {
  padding: 0px 60px !important;
  margin-top: 10px !important;
  margin-bottom: 5px;
}

@media (max-width: 767px) {
  .image-set-retreat {
    padding: unset !important;
  }
}

@media (max-width: 991px) {
  .addDate {
    margin-top: 37px;
  }

  .deleteButton {
    margin-top: 10px;
  }

  .selctSubmit {
    margin-left: unset;
    margin-top: 5px;
  }
  .selct-role-Submit {
    margin-left: unset;
    margin-top: 61px;
  }

  .selct-access-Submit {
    margin-left: unset;
  }
}

.deleteMessage {
  font-size: 15px;
  line-height: 20px;
}

.restore-delete {
  justify-content: space-between;
}

.button-33 {
  appearance: none;
  background-color: #2ea44f;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

.button-33:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-33:hover {
  background-color: #2c974b;
}

.button-33:focus {
  box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
  outline: none;
}

.button-33:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, 0.1);
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
}

.button-33:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
}

.button-35 {
  appearance: none;
  background-color: rgb(108, 8, 8);
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

.button-35:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-35:hover {
  background-color: #2c974b;
}

.button-35:focus {
  box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
  outline: none;
}

.button-35:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, 0.1);
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
}

.button-35:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
}

.greenTick {
  color: green;
  font-size: 30px;
  font-weight: bold;
  margin-top: 95px;
}

@media (min-width: 991px) and (max-width: 1157px) {
  .edit-profile-img img {
    width: 170px;
  }

  .image-set-retreat {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}

.table-headings {
  background-color: rgb(244, 245, 247);
}

.directorydata {
  display: flex;
  margin-bottom: 6px;
}
@media (max-width: 1193px) {
  .directorydata {
    display: unset;
  }
}

.eyeIcon {
  float: right;
  margin-top: -28.5px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 22px;
}

.eyeIcon1 {
  float: right;
  margin-top: -28.5px;
  margin-right: 30px;
  cursor: pointer;
  font-size: 22px;
}

.upload-retreat-image {
  width: 141px;
}

.directory-header-image {
  float: right;
  margin-top: -11px;
  margin-right: -30px;
}

.actions-couple {
  margin-left: 1px;
}

.dateShowField {
  width: 95px !important;
  height: 38px !important;
  border-radius: 5px !important;
  padding: 10px !important;
}

.editDate {
  height: 37px;
  width: 36px;
  margin-left: 18px;
  margin-top: 97px;
}

.action-icons {
  margin-top: 5px;
}

.faceBbook-link {
  margin-top: 2.5rem;
  margin-bottom: 0.5rem !important;
}

.footer-buttons {
  padding: 7px 31px !important;
}

.footer-buttons button {
  padding: 10px !important;
}

#contained-modal-title-vcenter {
  font-weight: 700;
}

@media (max-width: 1280px) {
  #signedIn {
    margin-left: 8px;
  }
}

.login-options {
  display: flex;
}

@media (min-width: 990px) and (max-width: 1130px) {
  .login-options {
    display: grid;
  }
}

@media (max-width: 575px) {
  .submitRet {
    float: left;
    margin-top: -2px;
    margin-left: 7px;
    margin-bottom: 10px;
  }

  .logo_image {
    height: 100px;
  }

  .logo_image5 {
    height: 100px;
  }

  .lovestrongLink {
    font-size: 20px;
  }
}

.align-date-fields {
  display: flex;
}

.timeFields {
  display: flex;
  margin-left: 15px;
}

.inputDate {
  margin-left: -17px;
}

.inputDate1 {
  margin-left: 8px;
}

.timeIcons {
  margin-left: 8px;
}

.delete-icon8 {
  margin-left: 15px;
}

@media (min-width: 767px) and (max-width: 1322px) {
  .align-date-fields {
    display: unset;
  }

  .timeFields {
    justify-content: space-around;
  }
}

@media (max-width: 409px) {
  .align-date-fields {
    display: unset;
  }

  .timeFields {
    justify-content: space-around;
  }
}

.parishes-logos {
  justify-content: space-around;
}

@media (min-width: 990px) and (max-width: 1310px) {
  .coupleImages5 img {
    height: 90px !important;
    width: 150px !important;
  }

  .parishLogo1 {
    max-width: 122px;
  }

  .parishDetail h3 {
    font-weight: 700;
    font-size: 15px;
  }

  .parishDetail h5 {
    font-weight: 700;
    font-size: 10px;
  }

  .parishLogo {
    max-height: 160px;
    max-width: 160px;
  }
}

@media (min-width: 767px) and (max-width: 1245px) {
  .add-service-buttons {
    width: 8rem;
  }

  .add-role-couple {
    width: 10rem;
  }

  .selctSubmit {
    margin-left: 12px;
  }

  .selct-role-Submit {
    margin-left: 20px;
  }

  .sideProgram h5 {
    font-weight: 700;
    font-size: 12px;
  }

  .uploadMdi1 {
    font-size: 23px;
  }

  .uploadMdi {
    font-size: 23px;
  }

  .plus-icon-role {
    font-size: 23px;
  }

  .sideFields {
    padding: 0px !important;
  }
}

@media (max-width: 1245px) {
  .add-service-buttons {
    margin-bottom: 18px;
  }

  .add-role-couple {
    margin-bottom: 18px;
  }
}

@media (max-width: 1000px) {
  .directory_footer {
    padding: 10px 60px !important;
  }
}

@media (max-width: 616px) {
  .footer-logo-image1 {
    height: 100px;
  }
}

@media (max-width: 815px) {
  .imageHeader {
    text-align: center !important;
  }

  .coupl_detail {
    text-align: center !important;
    margin-top: 20px !important;
  }
}

@media (min-width: 991px) {
  .margn-lef {
    margin-right: -21px !important;
  }
}

.multiSelect {
  z-index: 1 !important;
}

.se-link-preview {
  margin-top: 4px !important;
}

.roaster_list {
  padding: 50px;
}

.model_data {
  color: black;
  font-weight: 500;
  padding: 50px;
}

.program_couple {
  padding: 50px;
}

@media (max-width: 420px) {
  #dropdown-button-drop-start {
    padding: 2px !important;
  }

  #dropdown-button-drop-start img {
    width: 34px;
    height: 24px;
    margin-right: unset !important;
  }

  .mdi-help-circle-outline {
    font-size: 22px !important;
    margin-left: -21px;
  }

  .roaster_list {
    padding: unset !important;
  }
  .delete-icon {
    margin-left: -11px;
  }

  .model_data {
    padding: 3px;
  }

  .program_couple {
    padding: 3px;
  }

  .speakersQuestions h3 {
    font-size: 25px;
    font-weight: 700;
  }

  .speakersQuestions h4 {
    font-size: 20px;
    font-weight: 600;
  }

  .lovestrongLink {
    font-size: 13px;
  }

  .coupl_detail {
    padding: 0px;
    padding-left: 0px !important;
  }
}

@media (max-width: 501px) {
  .coupleImages img {
    height: 150px;
    width: 160px;
  }

  .directoryHeaderInMain img {
    height: 105px;
  }
  .footer-lovestrong-logo {
    height: 105px;
  }

  .coupl_detail h2 {
    font-size: 18px;
  }
  .coupl_detail h4 {
    font-size: 12px;
  }
  .directoryHeaderInMain div h1 {
    font-size: 30px;
  }
}
.sun-editor .se-toolbar {
  z-index: unset !important;
}

#outlined-adornment-quote {
  height: 50px !important;
}

.retreatCouples {
  float: right;
  margin-top: -40px;
  padding-right: 4px !important;
}

.remove_couple {
  float: right;
  margin-top: -29px;
  position: sticky;
}

.cardOfLead {
  height: 280px;
  border: 1px solid rgba(255, 173, 132, 255) !important;
}

.cardOfLead img {
  object-fit: contain;
  padding: 20px !important;
}

.export-approval-couple {
  height: 38px;
}

.churchImg1 {
  padding: 0px 13px 0px 0px;
  height: auto !important;
  width: 300px !important;
  border: initial !important;
}

.churchImg2 {
  padding: 0px 13px 0px 0px;
  height: auto !important;
  width: 450px !important;
  border: initial !important;
}

.secondRetreatImage {
  height: auto !important;
  border: initial !important;
  padding: 0 0 0 13px;
  width: 300px !important;
}

.images_aligns {
  align-items: baseline !important;
}

@media (max-width: 1222px) {
  .retreat_img img {
    width: 180px !important;
  }
}

@media (max-width: 1253px) {
  /* .churchImage {
    display: grid;
    justify-content: center;
  } */

  .church_img {
    display: grid;
    justify-content: center;
  }

  .secondRetreatImage {
    margin-top: 15px;
    width: 270px !important;
  }

  .churchImg1 {
    margin-top: 15px;
    width: 270px !important;
  }
}

@media (max-width: 1141px) {
  .secondRetreatImage {
    padding: 0px !important;
  }

  .churchImg1 {
    padding: 0px !important;
  }
}

@media (max-width: 711px) {
  .secondRetreatImage {
    padding: 0px !important;
  }

  .churchImg1 {
    width: 250px !important;
  }

  .secondRetreatImage {
    width: 250px !important;
  }
}

.compLogo {
  text-align: center !important;
}
.compLogo img {
  width: 200px !important;
  margin: 20px;
}

.addAdmin {
  margin-top: 22px;
  margin-left: 94px;
}

.col-border-admin-access {
  border-right: 1px solid #ddd; /* Adjust the color and width as needed */
}

.col-border:last-child {
  border-right: none; /* Remove border for the last column */
}

.roleBasedAccess {
  display: flex !important;
  justify-content: space-between;
}

.retreatAdminTitle {
  font-weight: bold;
  color: #3b3838;
}

@media (max-width: 1296px) and (min-width: 991px) {
  .roleBasedAccess {
    display: block !important;
  }

  .roleBasedAccess label {
    margin-bottom: 12px;
  }
}

@media (max-width: 793px) and (min-width: 575px) {
  .roleBasedAccess {
    display: block !important;
  }

  .roleBasedAccess label {
    margin-bottom: 12px;
  }
}

@media (max-width: 440px) {
  .roleBasedAccess {
    display: block !important;
  }

  .roleBasedAccess label {
    margin-bottom: 12px;
  }
}

.approve-button {
  margin-bottom: 10px;
}

.approve-button button {
  margin-left: 10px;
}

.heading-approval-retreat h4 {
  margin-left: 10px;
}

.approve-roaster-link {
  float: right;
}

.approve-roaster-link a {
  color: black;
  cursor: pointer;
  font-weight: 600;
}

.newLabel {
  color: black;
  font-weight: 500;
}

.newAlert {
  margin-top: 30px;
}

.timeManage {
  justify-content: space-between;
}

.editTime {
  margin-top: 26px;
}

.sampleData {
  float: right;
  margin-top: -11px;
  text-decoration: underline !important;
  color: blue !important;
  font-weight: 600 !important;
  cursor: pointer;
  font-size: 15px;
}

.react-datepicker-popper {
  z-index: 2;
}

.plus-icon-register {
  font-size: 30px;
}

@media (max-width: 768px) {
  .timeData {
    margin-top: 10px !important;
  }
}

.regiter_refresh_button {
  margin-top: 27px;
}

.dark-option {
  color: #1c1c1c; /* White text for contrast */
}

.dateRetreatRegister {
  width: 200px !important;
  height: 38px !important;
  border-radius: 5px !important;
  padding: 10px !important;
}

.border-right {
  border-right: 1px solid #ccc; /* Adjust color and thickness as needed */
  padding-right: 15px; /* Optional, to add some padding */
}

.tp_inputBox {
  width: 91px !important;
  font-size: 15px !important;
}

.tp_iconWrapper {
  padding-right: 2px !important;
}

.dateShedule {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1335px) {
  .dateShedule {
    display: unset !important;
  }
}

.dateSize {
  font-size: 13px;
}

.editRetreatButton {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.editRetreatDisabled {
  --bs-text-opacity: 0.5;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.deleteRetreatButton {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.deleteRetreatDisabled {
  --bs-text-opacity: 0.5;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.exportHover:hover {
  background: #7ca7fc !important;
  color: #ffffff;
}

.toggle-on .form-check-input:checked {
  background-color: green !important;
  border-color: green !important;
}

.toggle-on-status .form-check-input:checked {
  background-color: green !important;
  border-color: green !important;
  height: 17px;
  width: 17px;
}

.toggle-off-status .form-check-input {
  -webkit-appearance: none; /* Hides the default checkbox */
  height: 17px;
  width: 17px;
  display: flex; /* Flexbox for centering */
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
  transition: 0.1s;
  background-color: #fe0006;
  text-align: center;
  font-weight: 100 !important;
  color: white;
  border-radius: 3px;
  outline: none;
  border: unset !important;
  padding: 0; /* Ensures no extra padding inside */
}

.toggle-off-status .form-check-input:before {
  content: "✖";
  font-size: 15px;
  display: inline-block;
  line-height: 1; /* Adjusts line height for centering */
}

.toggle-off-status .form-check-input:hover {
  cursor: pointer;
  opacity: 0.8;
}

.confirm-toggle {
  margin-top: -5px;
}

.confirmButtons {
  justify-content: end;
}

.leftSideButtons {
  margin-right: 30px;
}

.upload_dir {
  margin-left: 5px;
}

.red-dot {
  width: 5px; /* Diameter of the dot */
  height: 5px; /* Diameter of the dot */
  background-color: red; /* Color of the dot */
  border-radius: 50%; /* Makes it a circle */
  display: inline-block; /* Ensures it behaves like a dot */
}

.green-dot {
  width: 5px; /* Diameter of the dot */
  height: 5px; /* Diameter of the dot */
  background-color: green; /* Color of the dot */
  border-radius: 50%; /* Makes it a circle */
  display: inline-block; /* Ensures it behaves like a dot */
}

.retreat_closure {
  font-size: 10px;
}

.noConfirmLabel {
  color: red;
}

.confirmLabel {
  color: green;
}

.message-container {
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  text-align: start;
}

.message-container h4 {
  line-height: 27px;
}

.message-container a {
  color: #3498db;
  text-decoration: none;
}

.message-container a:hover {
  text-decoration: underline;
}

.file_side {
  margin-left: 28px;
}

.checkStatus {
  gap: 8px;
}

.switchColor .form-check-input {
  background-color: red !important;
}

.roasterKey {
  width: 44px;
  display: inline-block;
  height: 34px;
  margin-right: 0.2rem;
  text-align: center;
  border: 1px solid #b6b6b6;
}

.img-assumption {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.language-selection {
  color: black !important;
}
